export const FETCH_TOTAL_COUNT_INIT_ACTION = "FETCH_TOTAL_COUNT_INIT_ACTION";
export const FETCH_TOTAL_COUNT_SUCCESS_ACTION =
  FETCH_TOTAL_COUNT_INIT_ACTION + "_SUCCESS";
export const FETCH_TOTAL_COUNT_FAIL_ACTION =
  FETCH_TOTAL_COUNT_INIT_ACTION + "_ERROR";

export const FETCH_VERIFIED_COUNT_INIT_ACTION =
  "FETCH_VERIFIED_COUNT_INIT_ACTION";
export const FETCH_VERIFIED_COUNT_SUCCESS_ACTION =
  FETCH_VERIFIED_COUNT_INIT_ACTION + "_SUCCESS";
export const FETCH_VERIFIED_COUNT_FAIL_ACTION =
  FETCH_VERIFIED_COUNT_INIT_ACTION + "_ERROR";

export const FETCH_ATTEMPTED_COUNT_INIT_ACTION =
  "FETCH_ATTEMPTED_COUNT_INIT_ACTION";
export const FETCH_ATTEMPTED_COUNT_SUCCESS_ACTION =
  FETCH_ATTEMPTED_COUNT_INIT_ACTION + "_SUCCESS";
export const FETCH_ATTEMPTED_COUNT_FAIL_ACTION =
  FETCH_ATTEMPTED_COUNT_INIT_ACTION + "_ERROR";

export const FETCH_PENDING_COUNT_INIT_ACTION =
  "FETCH_PENDING_COUNT_INIT_ACTION";
export const FETCH_PENDING_COUNT_SUCCESS_ACTION =
  FETCH_PENDING_COUNT_INIT_ACTION + "_SUCCESS";
export const FETCH_PENDING_COUNT_FAIL_ACTION =
  FETCH_PENDING_COUNT_INIT_ACTION + "_ERROR";

  export const FETCH_ALLOCATED_COUNT_INIT_ACTION =
  "FETCH_ALLOCATED_COUNT_INIT_ACTION";
export const FETCH_ALLOCATED_COUNT_SUCCESS_ACTION =
  FETCH_ALLOCATED_COUNT_INIT_ACTION + "_SUCCESS";
export const FETCH_ALLOCATED_COUNT_FAIL_ACTION =
  FETCH_ALLOCATED_COUNT_INIT_ACTION + "_ERROR";

  export const FETCH_UNALLOCATED_COUNT_INIT_ACTION =
  "FETCH_UNALLOCATED_COUNT_INIT_ACTION";
export const FETCH_UNALLOCATED_COUNT_SUCCESS_ACTION =
  FETCH_UNALLOCATED_COUNT_INIT_ACTION + "_SUCCESS";
export const FETCH_UNALLOCATED_COUNT_FAIL_ACTION =
  FETCH_UNALLOCATED_COUNT_INIT_ACTION + "_ERROR";

  export const FETCH_TOTALUSER_COUNT_INIT_ACTION =
  "FETCH_TOTALUSER_COUNT_INIT_ACTION";
export const FETCH_TOTALUSER_COUNT_SUCCESS_ACTION =
FETCH_TOTALUSER_COUNT_INIT_ACTION + "_SUCCESS";
export const FETCH_TOTALUSER_COUNT_FAIL_ACTION =
FETCH_TOTALUSER_COUNT_INIT_ACTION + "_ERROR";

export const FETCH_ACTIVEUSER_COUNT_INIT_ACTION =
"FETCH_ACTIVEUSER_COUNT_INIT_ACTION";
export const FETCH_ACTIVEUSER_COUNT_SUCCESS_ACTION =
FETCH_ACTIVEUSER_COUNT_INIT_ACTION + "_SUCCESS";
export const FETCH_ACTIVEUSER_COUNT_FAIL_ACTION =
FETCH_ACTIVEUSER_COUNT_INIT_ACTION + "_ERROR";

export const FETCH_INACTIVEUSER_COUNT_INIT_ACTION =
"FETCH_INACTIVEUSER_COUNT_INIT_ACTION";
export const FETCH_INACTIVEUSER_COUNT_SUCCESS_ACTION =
FETCH_INACTIVEUSER_COUNT_INIT_ACTION + "_SUCCESS";
export const FETCH_INACTIVEUSER_COUNT_FAIL_ACTION =
FETCH_INACTIVEUSER_COUNT_INIT_ACTION + "_ERROR";

export const FETCH_GRAPH_DATA_INIT_ACTION =
"FETCH_GRAPH_DATA_INIT_ACTION";
export const FETCH_GRAPH_DATA_SUCCESS_ACTION =
FETCH_GRAPH_DATA_INIT_ACTION + "_SUCCESS";
export const FETCH_GRAPH_DATA_FAIL_ACTION =
FETCH_GRAPH_DATA_INIT_ACTION + "_ERROR";


export const fetchTotalCount = (value) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_TOTAL_COUNT_INIT_ACTION,
      value: value,
    });
  };
};

export const fetchVerifiedCount = (value) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_VERIFIED_COUNT_INIT_ACTION,
      value: value,
    });
  };
};

export const fetchAttemptedCount = (value) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_ATTEMPTED_COUNT_INIT_ACTION,
      // value: value,
    });
  };
};

export const fetchPendingCount = (value) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_PENDING_COUNT_INIT_ACTION,
      value: value,
    });
  };
};

export const fetchAllocatedCount = (value) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_ALLOCATED_COUNT_INIT_ACTION,
      value: value,
    });
  };
};

export const fetchUnallocatedCount = (value) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_UNALLOCATED_COUNT_INIT_ACTION,
      value: value,
    });
  };
};

export const fetchTotalUserCount = (value) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_TOTALUSER_COUNT_INIT_ACTION,
      value: value,
    });
  };
};

export const fetchActiveUserCount = (value) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_ACTIVEUSER_COUNT_INIT_ACTION,
      value: value,
    });
  };
};

export const fetchInactiveUserCount = (value) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_INACTIVEUSER_COUNT_INIT_ACTION,
      value: value,
    });
  };
};

export const getGraphData = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_GRAPH_DATA_INIT_ACTION,
      // value: value,
    });
  };
};


export default {
  fetchVerifiedCount,
  fetchAttemptedCount,
  fetchPendingCount,
  fetchTotalCount,
  fetchAllocatedCount,
  fetchUnallocatedCount,
  fetchTotalUserCount,
  fetchActiveUserCount,
  fetchInactiveUserCount,
  getGraphData
};
