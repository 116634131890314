import * as actionTypes from "./constant";
import { put, takeEvery, } from "redux-saga/effects";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";

function* importData(action) {
    const token = localStorage.getItem("userToken");
    const file = action.value.file;
    try {
      let urls = "/verification/customer/import";
  
      let formData = new FormData();
      formData.append("file", file);
  
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };
  
      const response = yield fetch(`${appConfig.ip}${urls}`, {
        method: "POST",
        headers: headers,
        body: formData,
      });
  
      if (response.status === 409) {
        yield toast.error(
          "File upload failed: A customer with the same name already exists."
        );
        throw new Error("File upload conflict");
      } else if (!response.ok) {
        yield toast.error("File upload failed");
        if (response.status === 401) {
          yield localStorage.removeItem("userToken");
          yield localStorage.removeItem("role");
          yield localStorage.removeItem("userData");
          yield localStorage.removeItem("userFilters");
          yield localStorage.removeItem("partyFilters");
          yield put(push("/login"));
        }
        yield toast.error(action.toastErrorMessage || "Failed to upload file.");
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const resJSON = yield response.json();
      yield put({
        type: actionTypes.IMPORT_CUSTOMER_SUCCES_ACTION,
        payload: resJSON,
      });
      yield toast.success(action.toastMessage || "File uploaded successfully.");
      return resJSON;
    } catch (error) {
      yield put({ type: actionTypes.IMPORT_CUSTOMER_FAIL_ACTION, error });
    }
  }

  export function* CustomerImportActionWatcher() {
    yield takeEvery(
      actionTypes.IMPORT_CUSTOMER_INIT_ACTION,
      importData
    );
}