import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import DashboardReducer from "../containers/DefaultLayout/reducer";
import CustomerDetailsReducer from "../containers/CustomerViewContainer/reducer";
import MyCasesReducer from "../containers/MyCasesContainer/reducer";
import UserReducer from "../containers/UserContainer/reducer";
import CustomerBulkAssignReducer from "../containers/CustomerBulkAssign/reducer";
import LoginReducer from '../containers/LoginContainer/reducer'
import BulkUserAddReducer from '../containers/BulkUserAddContainer/reducer'
const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    DashboardReducer,
    CustomerDetailsReducer,
    MyCasesReducer,
    UserReducer,
    CustomerBulkAssignReducer,
    LoginReducer,
    BulkUserAddReducer
  });

export default createRootReducer;
