import * as actionTypes from './constant';
import {put, takeEvery,} from 'redux-saga/effects';
import {appConfig} from '../../config';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";

function* customerBulkAssign(action) {
    const token = localStorage.getItem("userToken");
    const file = action.value.file;
    try {
    let urls ='/verification/customerAssign/import';
      let formData = new FormData();
      formData.append("file", file);
  
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };
  
      const response = yield fetch(`${appConfig.ip}${urls}`, {
        method: "POST",
        headers: headers,
        body: formData,
      });

      if (!response.ok) {
        if (response.status === 401) {
          yield localStorage.removeItem("userToken");
          yield localStorage.removeItem("role");
          yield localStorage.removeItem("userData");
          yield localStorage.removeItem("userFilters");
          yield localStorage.removeItem("partyFilters");
          yield put(push("/login"));
        }
        yield toast.error(action.toastErrorMessage || "Failed to upload file.");
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const resJSON = yield response.json();
      yield put({
        type: actionTypes.ASSIGN_BULK_CUTOMER_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success(action.toastMessage || "File uploaded successfully.");
      return resJSON;
    } catch (error) {
      yield put({ type: actionTypes.ASSIGN_BULK_CUTOMER_FAIL_ACTION, error });
    }
  }
  

  export function* CustomerBulkAssign (){
    yield takeEvery(
        actionTypes.ASSIGN_BULK_CUTOMER_INIT_ACTION, customerBulkAssign
    )
  }