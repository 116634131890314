import React from "react";
import { Route, Switch, HashRouter } from "react-router-dom";

import Login from "../containers/LoginContainer";
import DefaultLayout from "../containers/DefaultLayout";

function AppNavigator() {
  return (
    <HashRouter basename="/">
      <React.Suspense>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <DefaultLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}
export default AppNavigator;
