import React, { Component } from "react";
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  
  render() {
    // const currentDate = new Date();  
    // const year = currentDate.getFullYear();  
    // const { children, ...attributes } = this.props;
    // const {  } = this.props;


    return (
      <React.Fragment>
        <div className="footerHeight"> 
        <span style={{textAlign:"right", marginRight:"50px"}}>
          {process.env.REACT_APP_VERSION}
        </span>
        </div>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;

