// const userRole = localStorage.getItem('role');

export const navLicensee = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "fa fa-th-large",
    },

    {
      name: "My Parties",
      url: "/my parties",
      icon: "fa fa-file-text",
    },
    {
      name: "Users",
      url: "/users",
      icon: "fa fa-users",
    },
    {
      name: "Bulk Assign",
      url: "/Bulk Assign",
      icon: 'fa fa-clipboard'
    },
    {
      name: "User Import",
      url: "/User Import",
      icon: 'fa fa-user-plus'
    },
    // ...(userRole === "admin"
    //   ? [ 
          // {
          //   name: "Import Parties",
          //   url: "/Import Parties",
          //   icon: 'fas fa-download',
          // },
      //   ]
      // : []),
    {
      name: "Report",
      icon: "fa fa-clipboard",
         children: [
          {
            name:  "All Activities",
            url:"/All Activities",
          },
        {
          name: "Verification Report",
          url: "/Verification Report",
        },
   
      ],
    },
  ],
};
