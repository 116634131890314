export const ADD_PROJECT_DATA_INIT_ACTION = "ADD_PROJECT_DATA_INIT_ACTION";
export const ADD_PROJECT_DATA_SUCCESS_ACTION =
  ADD_PROJECT_DATA_INIT_ACTION + "_SUCCESS";
export const ADD_PROJECT_DATA_FAIL_ACTION =
  ADD_PROJECT_DATA_INIT_ACTION + "_ERROR";

export const FETCH_REPORT_INIT_ACTION = "FETCH_REPORT_INIT_ACTION";
export const FETCH_REPORT_SUCCESS_ACTION =
  FETCH_REPORT_INIT_ACTION + "_SUCCESS";
export const FETCH_REPORT_FAIL_ACTION = FETCH_REPORT_INIT_ACTION + "_ERROR";

export const FETCH_VERIFICATION_INIT_ACTION = "FETCH_VERIFICATION_INIT_ACTION";
export const FETCH_VERIFICATION_SUCCESS_ACTION =
  FETCH_VERIFICATION_INIT_ACTION + "_SUCCESS";
export const FETCH_VERIFICATION_FAIL_ACTION =
  FETCH_VERIFICATION_INIT_ACTION + "_ERROR";

export const ADD_PROJECTS_INIT_ACTION = "ADD_PROJECTS_INIT_ACTION";
export const ADD_PROJECTS_SUCCESS_ACTION =
  ADD_PROJECTS_INIT_ACTION + "_SUCCESS";
export const ADD_PROJECTS_FAIL_ACTION = ADD_PROJECTS_INIT_ACTION + "_ERROR";

export const UPDATE_PROJECTS_DATA_INIT_ACTION =
  "UPDATE_PROJECTS_DATA_INIT_ACTION";
export const UPDATE_PROJECTS_DATA_SUCCESS_ACTION =
  UPDATE_PROJECTS_DATA_INIT_ACTION + "_SUCCESS";
export const UPDATE_PROJECTS_DATA_FAIL_ACTION =
  UPDATE_PROJECTS_DATA_INIT_ACTION + "_ERROR";

export const FETCH_CUSTOMERBYID_INIT_ACTION = "FETCH_CUSTOMERBYID_INIT_ACTION";
export const FETCH_CUSTOMERBYID_SUCCESS_ACTION =
  FETCH_CUSTOMERBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_CUSTOMERBYID_FAIL_ACTION =
  FETCH_CUSTOMERBYID_INIT_ACTION + "_ERROR";

  export const ADD_ACTIVITYID_INIT_ACTION = "ADD_ACTIVITYID_INIT_ACTION";
export const ADD_ACTIVITYID_SUCCESS_ACTION =
ADD_ACTIVITYID_INIT_ACTION + "_SUCCESS";
export const ADD_ACTIVITYID_FAIL_ACTION =
ADD_ACTIVITYID_INIT_ACTION + "_ERROR";

export const FETCH_ALL_REPORT_INIT_ACTION = "FETCH_ALL_REPORT_INIT_ACTION";
export const FETCH_ALL_REPORT_SUCCESS_ACTION =
FETCH_ALL_REPORT_INIT_ACTION + "_SUCCESS";
export const FETCH_ALL_REPORT_FAIL_ACTION = FETCH_ALL_REPORT_INIT_ACTION + "_ERROR";

export const FETCH_VERIFIED_REPORT_INIT_ACTION = "FETCH_VERIFIED_REPORT_INIT_ACTION";
export const FETCH_VERIFIED_REPORT_SUCCESS_ACTION =
FETCH_VERIFIED_REPORT_INIT_ACTION + "_SUCCESS";
export const FETCH_VERIFIED_REPORT_FAIL_ACTION = FETCH_VERIFIED_REPORT_INIT_ACTION + "_ERROR";

export const EXPORT_REPORT_DATA_INIT_ACTION = "EXPORT_REPORT_DATA_INIT_ACTION";
export const EXPORT_REPORT_DATA_SUCCESS_ACTION =
EXPORT_REPORT_DATA_INIT_ACTION + "_SUCCESS";
export const EXPORT_REPORT_DATA_FAIL_ACTION = EXPORT_REPORT_DATA_INIT_ACTION + "_ERROR";

export const FETCH_CUSTOMER_REPORT_DATA_INIT_ACTION = "FETCH_CUSTOMER_REPORT_DATA_INIT_ACTION";
export const FETCH_CUSTOMER_REPORT_DATA_SUCCESS_ACTION = FETCH_CUSTOMER_REPORT_DATA_INIT_ACTION + "_SUCCESS";
export const FETCH_CUSTOMER_REPORT_DATA_FAIL_ACTION = FETCH_CUSTOMER_REPORT_DATA_INIT_ACTION + "_ERROR";


export const FETCH_ALL_ACTIVITY_INIT_ACTION = "FETCH_ALL_ACTIVITY_INIT_ACTION";
export const FETCH_ALL_ACTIVITY_SUCCESS_ACTION = FETCH_ALL_ACTIVITY_INIT_ACTION + "_SUCCESS";
export const FETCH_ALL_ACTIVITY_FAIL_ACTION  = FETCH_ALL_ACTIVITY_INIT_ACTION + "_ERROR";

export const FETCH_ALL_VERIFIED_ACTIVITY_INIT_ACTION = "FETCH_ALL_VERIFIED_ACTIVITY_INIT_ACTION";
export const FETCH_ALL_VERIFIED_ACTIVITY_SUCCESS_ACTION = FETCH_ALL_VERIFIED_ACTIVITY_INIT_ACTION + "_SUCCESS";
export const FETCH_ALL_VERIFIED_ACTIVITY_FAIL_ACTION  = FETCH_ALL_VERIFIED_ACTIVITY_INIT_ACTION + "_ERROR";
