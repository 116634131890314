import { put, takeEvery, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BroadcastChannel } from "broadcast-channel";

import {
  LOGIN_INIT_ACTION,
  FETCH_LOGIN_FAIL_ACTION,
  FETCH_LOGIN_SUCCESS_ACTION,
  LOGOUT_ACTION,
} from "./actions";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
const logoutChannel = new BroadcastChannel("token");

function* login(action) {
  try {
    const loginData = {
      mobileNo: action.cred.mobileNo,
      password: action.cred.password,
    };

    const res = yield fetch(`${appConfig.ip}/auth/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    });
    const resJSON = yield res.json();
    
    if (!res.ok) {
      
      if (res.status === 401) {
        yield localStorage.removeItem("userToken");
        yield localStorage.removeItem("role");
        yield localStorage.removeItem("userData");
        yield localStorage.removeItem("userFilters");
        yield localStorage.removeItem("partyFilters");
        yield put(push("/login"));
      } else if( res.status === 403) {
        yield toast.error("Invalid User");
      }
      const errorText = yield res.text();
      if (res.status === 401) {
        yield localStorage.removeItem("userToken");
        yield localStorage.removeItem("role");
        yield localStorage.removeItem("userData");
        yield localStorage.removeItem("userFilters");
        yield localStorage.removeItem("partyFilters");
        yield toast.error("Invalid credentials", {
          autoClose: 3000,
          position: "top-center",
        });
      }
      yield put({
        type: FETCH_LOGIN_FAIL_ACTION,
        payload: errorText,
      });
    } else {
      yield put({
        type: FETCH_LOGIN_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield call([localStorage, "setItem"], "role", resJSON.userData.role);
      yield call(
        [localStorage, "setItem"],
        "userToken",
        resJSON.backendTokens.accessToken
      );
      yield call(
        [localStorage, "setItem"],
        "userData",
        JSON.stringify(resJSON.userData)
      );
      const role = resJSON.userData.role;
      if (role === "admin" || role === "regionHead" || role === 'circleHead') {
        yield put(push("/dashboard"));
      } else {
        yield toast.error("Invalid User", {
          autoClose: 3000,
          position: "top-center",
        });
      }
    }
  } catch (err) {

    yield put({
      type: FETCH_LOGIN_FAIL_ACTION,
      payload: err.message,
    });
  }
}
function* logOut() {
  logoutChannel.postMessage("logout");
  yield localStorage.removeItem("userToken");
  yield localStorage.removeItem("role");
  yield localStorage.removeItem("userData");
  yield localStorage.removeItem("userFilters");
  yield localStorage.removeItem("partyFilters");

  yield put(push("/login"));
}

export function* LoginActionWatcher() {
  yield takeEvery(LOGIN_INIT_ACTION, login);
  yield takeEvery(LOGOUT_ACTION, logOut);
}
