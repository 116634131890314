import {
  FETCH_ACTIVEUSER_COUNT_SUCCESS_ACTION,
  FETCH_ALLOCATED_COUNT_SUCCESS_ACTION,
  FETCH_ATTEMPTED_COUNT_SUCCESS_ACTION,
  FETCH_GRAPH_DATA_SUCCESS_ACTION,
  FETCH_INACTIVEUSER_COUNT_SUCCESS_ACTION,
  FETCH_PENDING_COUNT_SUCCESS_ACTION,
  FETCH_TOTAL_COUNT_SUCCESS_ACTION,
  FETCH_TOTALUSER_COUNT_SUCCESS_ACTION,
  FETCH_UNALLOCATED_COUNT_SUCCESS_ACTION,
  FETCH_VERIFIED_COUNT_SUCCESS_ACTION,
} from "./action";

const initialState = {
  processing: false,
  error: false,
  totalCount: 0,
  verifiedCount: 0,
  attemptedCount: 0,
  pendingCount: 0,
  allocatedCount: 0,
  unAllocatedCount:0,
  totalUserCount : 0,
  activeUserCount: 0,
  inactiveUserCount:0,
  graphData: []
};

export default function DashboardReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TOTAL_COUNT_SUCCESS_ACTION:
      return {
        ...state,
        totalCount: action.payload,
      };
    case FETCH_VERIFIED_COUNT_SUCCESS_ACTION:
      return {
        ...state,
        verifiedCount: action.payload,
      };
    case FETCH_ATTEMPTED_COUNT_SUCCESS_ACTION:
      return {
        ...state,
        attemptedCount: action.payload,
      };
    case FETCH_PENDING_COUNT_SUCCESS_ACTION:
      return {
        ...state,
        pendingCount: action.payload,
      };
      case FETCH_ALLOCATED_COUNT_SUCCESS_ACTION:
        return {
          ...state,
          allocatedCount: action.payload,
        };
      case FETCH_UNALLOCATED_COUNT_SUCCESS_ACTION:

        return {
          ...state,
          unAllocatedCount: action.payload,
        };
      case FETCH_TOTALUSER_COUNT_SUCCESS_ACTION:
        
        return {
          ...state,
          totalUserCount: action.payload,
        };
      case FETCH_ACTIVEUSER_COUNT_SUCCESS_ACTION:

        return {
          ...state,
          activeUserCount: action.payload,
        };
      case FETCH_INACTIVEUSER_COUNT_SUCCESS_ACTION:
        return {
          ...state,
          inactiveUserCount: action.payload,
        };
        case FETCH_GRAPH_DATA_SUCCESS_ACTION:
        return {
          ...state,
          graphData: action.payload,
        };


    default:
      return {
        ...state,
      };
  }
}
