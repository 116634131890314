import * as actionTypes from "./actions";

const initialState = {
    role:''
}

export default function LoginReducer(state = initialState, action){
    switch (action.type) {
        case actionTypes.FETCH_LOGIN_SUCCESS_ACTION:
            return {
                ...state,
                role: action.payload.userData.role,
          };

   default :
   return {
    ...state,
  };
}
}