import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { ToastContainer } from "react-toastify";
import { BroadcastChannel } from "broadcast-channel";
import configureStore from "./configureStore";
import AppNavigator from "../Navigation";

const { store, history } = configureStore();

function App() {
  useEffect(() => {
    const channel = new BroadcastChannel("token");
    channel.addEventListener("message", handleMessage);
    return () => {
      channel.removeEventListener("message", handleMessage);
    };
  }, []);

  function handleMessage(event) {
    if (event === "logout") {
      window.location.reload();
    }
  }
  return (
    <Provider store={store}>
      <ToastContainer hideProgressBar={true} />
      <ConnectedRouter history={history}>
        <div>
          <AppNavigator />
        </div>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
