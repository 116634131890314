import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "./polyfill";
import "font-awesome/css/font-awesome.min.css";

import React from "react";
import ReactDOM from "react-dom";
import "./App.scss";
import boot from "./boot/index";
const App = boot();

ReactDOM.render(<App />, document.getElementById("root"));
