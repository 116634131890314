import * as actionTypes from "./constant";
import { put, takeEvery, call } from "redux-saga/effects";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";

function* commonFunction(value) {
  const token = localStorage.getItem("userToken");
  try {
    const response = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    
    if (!response.ok) {
      if (response.status === 401) {
        yield localStorage.removeItem("userToken");
        yield localStorage.removeItem("role");
        yield localStorage.removeItem("userData");
        yield localStorage.removeItem("userFilters");
        yield localStorage.removeItem("partyFilters");
        yield put(push("/login"));
      }
      if (value.toastErrorMessage) {
        yield toast.error(value.toastErrorMessage);
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      if (value.successAction === actionTypes.EXPORT_DATA_SUCCESS_ACTION) {
        const contentType = response.headers.get("Content-Type");

        if (
          contentType &&
          (contentType.includes("application/vnd.ms-excel") ||
            contentType.includes(
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) ||
            contentType.includes("text/csv"))
        ) {
          const blobResponse = yield response.blob();
          if (blobResponse instanceof Blob) {
            return blobResponse; 
          }
        } else {
          if (value.toastMessage) {
            yield toast.success();
          }
        }
      } else {
        const resJSON = yield response.json();

        yield put({
          type: `${value.successAction}`,
          payload: resJSON,
        });

        if (value.extraAction) {
          yield put({
            type: `${value.extraAction}`,
            payload: resJSON,
          });
        }

        if (value.toastMessage) {
          yield toast.success(value.toastMessage);
        }

        return resJSON;
      }
    }
  } catch (error) {
  }
}

function* fetchcustomerList(value) {
  const role = localStorage.getItem("role");
  let stateItem = value?.value?.stateItem || "";
  let distVal = value?.value?.distVal || "";
  let searchItem = value?.value?.searchItem || "";
  let status = value?.value?.status || "";
  let allocated = value?.value?.allocated || "";
  let page = value?.value?.page || 1;
  let pincode = value?.value?.pincode|| "";
  let assignedTo = value?.value?.assignedTo ;
  
  let endPoint = "";
  if (role === "admin") {
    if (stateItem || distVal || searchItem || allocated || status || assignedTo || pincode) {
      if (allocated === "Allocated") {
          if(status === "Verified"){
            endPoint = `verification/allocated?isVerified=true&page=${page}&limit=20&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          } else if (status === "Not Verified") {                        
            endPoint = `verification/allocated?isVerified=false&page=${page}&limit=20&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          } else {
            endPoint = `verification/allocated?page=${page}&limit=20&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          }

      } else if (allocated === "Unallocated") {
        if(status === "Verified"){
          endPoint = `verification/nonAllocated?isVerified=true&page=${page}&limit=20&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        } else if (status === "Not Verified") {
          endPoint = `verification/nonAllocated?isVerified=false&page=${page}&limit=20&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        } else {
          endPoint = `verification/nonAllocated?page=${page}&limit=20&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        }
      } else if (status === "Verified") {
        endPoint = `verification/customersByName?isVerified=true&state=${stateItem}&district=${distVal}&page=${page}&search=${searchItem}&limit=20&assignedTo=${assignedTo}&pin=${pincode}`;
      } else if (status === "Not Verified") {
        endPoint = `verification/customersByName?isVerified=false&state=${stateItem}&district=${distVal}&page=${page}&search=${searchItem}&limit=20&assignedTo=${assignedTo}&pin=${pincode}`;
      } else {
        endPoint = `verification/customersByName?state=${stateItem}&district=${distVal}&page=${page}&search=${searchItem}&limit=20&assignedTo=${assignedTo}&pin=${pincode}`;
      }
    } else {
      endPoint = `verification/customersByName?page=${page}&limit=20`;
    }
  } else if (role === "regionHead") {
    if (stateItem || distVal || searchItem || allocated || status || assignedTo || pincode) {
      if (allocated === "Allocated") {
        if(status === "Verified"){
          endPoint = `verification/allocationByState?isVerified=true&page=${page}&limit=20&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        } else if (status === "Not Verified") {
          endPoint = `verification/allocationByState?isVerified=false&page=${page}&limit=20&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        } else {
          endPoint = `verification/allocationByState?page=${page}&limit=20&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        }

      } else if (allocated === "Unallocated") {
          if(status === "Verified"){
            endPoint = `verification/nonAllocationByState?isVerified=true&page=${page}&limit=20&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          } else if (status === "Not Verified") {
            endPoint = `verification/nonAllocationByState?isVerified=false&page=${page}&limit=20&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          } else {
            endPoint = `verification/nonAllocationByState?page=${page}&limit=20&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          }
      } else if (status === "Verified") {
        endPoint = `verification/getCustomersByState?isVerified=true&state=${stateItem}&district=${distVal}&page=${page}&search=${searchItem}&limit=20&assignedTo=${assignedTo}&pin=${pincode}`;
      } else if (status === "Not Verified") {
        endPoint = `verification/getCustomersByState?isVerified=false&state=${stateItem}&district=${distVal}&page=${page}&search=${searchItem}&limit=20&assignedTo=${assignedTo}&pin=${pincode}`;
      } else {
        endPoint = `verification/getCustomersByState?state=${stateItem}&district=${distVal}&page=${page}&search=${searchItem}&limit=20&assignedTo=${assignedTo}&pin=${pincode}`;
      }
    } else {
        endPoint = `verification/getCustomersByState?page=${page}&limit=20`;
      }
  }

  try {
    let params = {
      page: page,
      api: `${appConfig.ip}/${endPoint}`,
      method: "GET",
      failAction: actionTypes.FETCH_CUSTOMERLIST_FAIL_ACTION,
      successAction: actionTypes.FETCH_CUSTOMERLIST_SUCCESS_ACTION,
    };
  yield call(commonFunction, params);
   
  } catch (error) {
  }
}

function* fetchCustomerReport(value) {
  const role = localStorage.getItem("role");
  let stateItem = value?.value?.stateItem || "";
  let distVal = value?.value?.distVal || "";
  let searchItem = value?.value?.searchItem || "";
  let status = value?.value?.status || "";
  let allocated = value?.value?.allocated || "";
  // let page = value?.value?.page || 1;
  let pincode = value?.value?.pincode|| "";
  let assignedTo = value?.value?.assignedTo
  
  let endPoint = "";
  if (role === "admin") {
    if (stateItem || distVal || searchItem || allocated || status || assignedTo || pincode) {
      if (allocated === "Allocated") {
          if(status === "Verified"){
            endPoint = `verification/allocated?isVerified=true&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          } else if (status === "Not Verified") {            
            endPoint = `verification/allocated?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          } else {
            endPoint = `verification/allocated?state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          }

      } else if (allocated === "Unallocated") {
        if(status === "Verified"){
          endPoint = `verification/nonAllocated?isVerified=tru&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        } else if (status === "Not Verified") {
          endPoint = `verification/nonAllocated?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        } else {
          endPoint = `verification/nonAllocated?state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        }
      } else if (status === "Verified") {
        endPoint = `verification/customersByName?isVerified=true&state=${stateItem}&district=${distVal}&search=${searchItem}&assignedTo=${assignedTo}&pin=${pincode}`;
      } else if (status === "Not Verified") {
        endPoint = `verification/customersByName?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}&assignedTo=${assignedTo}&pin=${pincode}`;
      } else {
        endPoint = `verification/customersByName?state=${stateItem}&district=${distVal}&search=${searchItem}&assignedTo=${assignedTo}&pin=${pincode}`;
      }
    } else {
      endPoint = `verification/customersByName?`;
    }
  } else if (role === "regionHead") {
    if (stateItem || distVal || searchItem || allocated || status || assignedTo || pincode) {
      if (allocated === "Allocated") {
        if(status === "Verified"){
          endPoint = `verification/allocationByState?isVerified=true&e=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        } else if (status === "Not Verified") {
          endPoint = `verification/allocationByState?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        } else {
          endPoint = `verification/allocationByState?state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
        }

      } else if (allocated === "Unallocated") {
          if(status === "Verified"){
            endPoint = `verification/nonAllocationByState?isVerified=true&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          } else if (status === "Not Verified") {
            endPoint = `verification/nonAllocationByState?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          } else {
            endPoint = `verification/nonAllocationByState?state=${stateItem}&district=${distVal}&search=${searchItem}&pin=${pincode}&assignedTo=${assignedTo}`;
          }
      } else if (status === "Verified") {
        endPoint = `verification/getCustomersByState?isVerified=true&state=${stateItem}&district=${distVal}&search=${searchItem}&assignedTo=${assignedTo}&pin=${pincode}`;
      } else if (status === "Not Verified") {
        endPoint = `verification/getCustomersByState?isVerified=false&state=${stateItem}&district=${distVal}&search=${searchItem}&assignedTo=${assignedTo}&pin=${pincode}`;
      } else {
        endPoint = `verification/getCustomersByState?state=${stateItem}&district=${distVal}&search=${searchItem}&assignedTo=${assignedTo}&pin=${pincode}`;
      }
    } else {
        endPoint = `verification/getCustomersByState?`;
      }
  }

  try {
    let params = {
      api: `${appConfig.ip}/${endPoint}`,
      method: "GET",
      failAction: actionTypes.EXPORT_ALL_CUSTOMER_FAIL_ACTION,
      successAction: actionTypes.EXPORT_ALL_CUSTOMER_SUCCESS_ACTION,
    };
   yield call(commonFunction, params); 
   
  } catch (error) {
  }
}

function* changeStatus(value) {
  let id = value.value.id;
  let data = {
    ...value.value,
    status:
      value.value.status === "draft"
        ? "publish"
        : "deleted"
        ? "draft"
        : "draft",
  };

  try {
    let params = {
      // api: `${appConfig.ip}/project/${id}`,
      method: "PUT",
      successAction: actionTypes.CHANGE_STATUS_PROJECT_SUCCESS_ACTION,
      failAction: actionTypes.CHANGE_STATUS_PROJECT_FAIL_ACTION,
      body: JSON.stringify(data),
    };
    yield call(commonFunction, params);
    // let res = yield call(commonFunction, params);
    yield toast.success("Status updated successfully", {
      autoClose: 3000,
    });
  } catch (err) {}
}

function* fetchState(value) {
  try {
    let page = (value.value && value.value.page);
    let limit = (value?.value?.limit && value?.value?.limit) || 100;
    let role = localStorage.getItem("role");
    let url = "";
    if (role === "admin") {
      if(page !== '') {
        url = `state?page=${page}&limit=${limit}`;
      } else {
        url = `state?page=1&limit=100`;

      }
    } else {
      if(page !== '') {
        url = `current/state?page=${page}&limit=${limit}`;
      } else {
        url = `current/state?page=1&limit=100`;
      }
    }

    let params = {
      page: page,
      api: `${appConfig.ip}/masterdata/${url}`,
      method: "GET",
      successAction: actionTypes.FETCH_STATE_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_STATE_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}
function* fetchDistrict(value) {
  try {
    let page = (value.value && value.value.page) || 1;
    let searchVal =
      value && value.value && value.value.value ? value.value.value : "";
    let urls = "";
    if (searchVal !== '') {
      urls = `state=${searchVal}&page=${page}&limit=1000`;
    } else {
      urls = `page=${page}&limit=1000`;
    }

    let params = {
      page: page,
      api: `${appConfig.ip}/masterdata/district?${urls}`,
      method: "GET",
      successAction: actionTypes.FETCH_DISTRICT_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_DISTRICT_FAIL_ACTION,
    };
    yield call(commonFunction, params);
  } catch (error) {}
}
function chunkArray(array, chunkSize) {
  let chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

function* exportData(value) {
  const format = value?.value?.format;
  const customerList = value?.value?.customerList || [];
  
  const chunkSize = 10000; 
  const customerChunks = chunkArray(customerList, chunkSize);

  try {
    const allBlobs = [];

    for (const chunk of customerChunks) {
      const data = { customerId: chunk };
      const urls = `/verification/customers/export?format=${format}`;

      let params = {
        api: `${appConfig.ip}${urls}`,
        method: "POST",
        successAction: actionTypes.EXPORT_DATA_SUCCESS_ACTION,
        failAction: actionTypes.EXPORT_DATA_FAIL_ACTION,
        body: JSON.stringify(data),
        toastErrorMessage: "Failed to export data",
      };

      const blobResponse = yield call(commonFunction, params);

      if (blobResponse instanceof Blob) {
        allBlobs.push(blobResponse); 
      }
    }

    const combinedBlob = new Blob(allBlobs, { type: allBlobs[0].type });

    const url = window.URL.createObjectURL(combinedBlob);
    const a = document.createElement("a");
    a.href = url;

    if (format === "excel") {
      a.download = `Customer_Report_${Date.now()}.xlsx`;
    } else {
      a.download = `Customer_Report_${Date.now()}.csv`;
    }
    
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

    yield toast.success("Successfully exported  data");
    
  } catch (error) {
  }
}

function* importData(action) {
  const token = localStorage.getItem("userToken");
  const file = action.value.file;
  try {
    let urls = "/verification/customer/import";

    let formData = new FormData();
    formData.append("file", file);

    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };

    const response = yield fetch(`${appConfig.ip}${urls}`, {
      method: "POST",
      headers: headers,
      body: formData,
    });

    if (response.status === 409) {
      yield toast.error(
        "File upload failed: A customer with the same name already exists."
      );
      throw new Error("File upload conflict");
    } else if (!response.ok) {
      yield toast.error("File upload failed");
      if (response.status === 401) {
        yield localStorage.removeItem("userToken");
        yield localStorage.removeItem("role");
        yield localStorage.removeItem("userData");
        yield localStorage.removeItem("userFilters");
        yield localStorage.removeItem("partyFilters");
        yield put(push("/login"));
      }
      yield toast.error(action.toastErrorMessage || "Failed to upload file.");
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const resJSON = yield response.json();
    yield put({
      type: actionTypes.IMPORT_DATA_SUCCESS_ACTION,
      payload: resJSON,
    });
    yield toast.success(action.toastMessage || "File uploaded successfully.");
    return resJSON;
  } catch (error) {
    // console.error("Error in importData:", error);
    yield put({ type: actionTypes.IMPORT_DATA_FAIL_ACTION, error });
    yield toast.error("Failed to upload file");
  }
}

function* fetchPartyChangelogById(value) {
   let  urls = '';
  try{
    let params = {
     api: `${appConfig.ip}/?${urls}`,
      method: "GET",
      successAction: actionTypes.FETCH_PARTY_CHANGELOGBYID_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_PARTY_CHANGELOGBYID_FAIL_ACTION,
    };

    yield call(commonFunction, params)

  } catch (e){

  }
}

function* PartyEditById(value) {
  let  urls = '';
 try{
   let params = {
    api: `${appConfig.ip}/?${urls}`,
     method: "GET",
     successAction: actionTypes.FETCH_PARTY_CHANGELOGBYID_SUCCESS_ACTION,
     failAction: actionTypes.FETCH_PARTY_CHANGELOGBYID_FAIL_ACTION,
   };

   yield call(commonFunction, params)

 } catch (e){

 }
}

export function* MyCasesActionWatcher() {
  yield takeEvery(
    actionTypes.FETCH_CUSTOMERLIST_INIT_ACTION,
    fetchcustomerList
  );
  yield takeEvery(actionTypes.CHANGE_STATUS_PROJECT_INIT_ACTION, changeStatus);
  yield takeEvery(actionTypes.FETCH_STATE_INIT_ACTION, fetchState);
  yield takeEvery(actionTypes.FETCH_DISTRICT_INIT_ACTION, fetchDistrict);
  yield takeEvery(actionTypes.EXPORT_DATA_INIT_ACTION, exportData);
  yield takeEvery(actionTypes.IMPORT_DATA_INIT_ACTION, importData);
  yield takeEvery(actionTypes.EXPORT_ALL_CUSTOMER_INIT_ACTION, fetchCustomerReport)
  yield takeEvery(actionTypes.FETCH_PARTY_CHANGELOGBYID_INIT_ACTION, fetchPartyChangelogById)
  yield takeEvery(actionTypes.PARTY_EDITBYID_INIT_ACTION, PartyEditById)
}
