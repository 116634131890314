import * as actionTypes from "./constants";

const initialState = {
    addUsersFailedData:[]
}

export default function BulkUserAddReducer(state = initialState, action){
    switch (action.type) {
        case actionTypes.BULK_USER_ADD_SUCCESS_ACTION:
          
          return {
            ...state,
            addUsersFailedData: action.payload.userStatus,
          };
        case actionTypes.CLEAR_BULK_USER_ADD_INIT_ACTION:
          return {
            ...state,
            addUsersFailedData: action.payload
          }
        
   default :
   return {
    ...state,
  };
}
}